
import FunFacts from "./FunFacts";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactCarousel from "./ReactCarousel";


const AboutMe = () => {

	const style = {color: '#0e65d7'};
    const sm = '12', lg = '6';// xm = 1,

    return <>
        <div className='about-area mt-5 pb-3'>
			<div className="text-center pb-3 border-bottom-0">
				<h1 style={style}>
					The leading Tax Service Provider
				</h1>
				<h2>
					<span style={style} >for </span>your <span style={style} >personal</span>,
					<span style={style} > and </span>your <span style={style} >business </span>needs!
				</h2>
			</div>
			<FunFacts />
			<Container className="justify-items-center">
				<Row className="justify-items-center">
					<Col sm={sm} lg={lg} >
						<Row>
							<div className="pt-3">
								<h5 className="text-center" >Who I am!</h5>
									<p>
										HI! &#128075;&#127998; my name is Georgia Winston, I'm a mother of 3 beautiful children and we love to travel anywhere there's a beach and warm sunlight. I attended college and had all intentions on finishing a BS in Business Administration but I wasn't able to finish because I wasn't able to afford childcare. So I decided to open an in-home childcare service and that was the start of my life as an entrepreneur.  I now have 6 years experience in the tax industry with 3 years as a professional Tax Consultant.
									</p>
									<p>
										As a single mother it was extremely difficult to work different odd jobs using subsidy programs to offset the cost of daycare, but it wasn't enough to be able to feed my daughter and keep up with college debt.  I love working with children and being part of their development. It also gave me the freedom to work and raise my children full-time.
									</p>
									<p>
										The Daycare business taught me that running a business is not just about management and employees, it's also about finances and taxes.  I decided to learn about individual and corporate tax regulation, and business accounting to protect myself. I started by filling my own taxes, promoting the Tax Business on social media, to the childcare parents, family, and friends. I quickly realized that Tax Preparation gave me more freedom and financial stability.  Now I have a very successful Tax Software and Tax Preparation Business and financial freedom. I've been able to purchase my dream car and my dream home.
									</p>
									<h5 className="text-center" >My Commitment to You!</h5>
									<p>
										I truly enjoy working with you and helping your family and business with your taxes. My goal is to handle your diverse tax needs while giving each of you the personal attention you deserve.  “Taxpayers have the right to pay no more than the correct amount of tax” - The Taxpayer Bill of Rights.  So, my goal is to look out for your bottom line by legally minimizing your tax liability so that you can keep the most money in your pocket.  I'm also dedicated to helping other tax preparers grow their business by providing training, mentorship, and a business  opportunity.
									</p>
							</div>
							<NavLink to="/contact" className=" mt-3 btn btn-outline-primary">
								Contact us
							</NavLink>
						</Row>
					</Col>
					
					<Col className="d-flex align-items-center pt-5" sm={sm} lg={lg}>
						<Row>
							<ReactCarousel page='aboutme'/>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	</>;
};

export default AboutMe;
